import React, { useEffect, useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { FlyToInterpolator } from 'react-map-gl'

import * as d3 from 'd3'
import t from 'prop-types'
import {
  Card,
  Divider,
  Grid,
  Header,
  Image,
  List,
  ListHeader,
  ListItem,
} from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import Loading from '~/components/Loading'
import api from '~/services/api'

import { Map } from './Map'

export default function Delivery({ id, place, missionName }) {
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)
  const [lightBox, setLightBox] = useState(false)
  const [photos, setPhotos] = useState([])

  async function getData() {
    setLoading(true)
    const res = await api.get(`delivery/registers/${id}`)
    setLoading(false)
    setResult(res.data)

    if (res.data?.collectFeed) {
      setViewPortCollect({
        zoom: 16,
        transitionDuration: 5000,
        transitionEasing: d3.easeCubic,
        latitude: res.data?.collectFeed[0]?.lat,
        longitude: res.data?.collectFeed[0]?.lng,
        transitionInterpolator: new FlyToInterpolator(),
      })
    }

    if (res?.data?.delivery_lat !== null) {
      setViewPortDelivery({
        zoom: 16,
        transitionDuration: 5000,
        transitionEasing: d3.easeCubic,
        latitude: Number(res.data?.result?.delivery_lat),
        longitude: Number(res.data?.result?.delivery_lng),
        transitionInterpolator: new FlyToInterpolator(),
      })
    }
  }

  const viewportDefault = {
    zoom: 16,
    latitude: 0,
    longitude: 0,
    transitionDuration: 5000,
    transitionEasing: d3.easeCubic,
    transitionInterpolator: new FlyToInterpolator(),
  }

  const [viewportCollect, setViewPortCollect] = useState({
    ...viewportDefault,
  })

  const [viewportDelivery, setViewPortDelivery] = useState({
    ...viewportDefault,
  })

  function onClickCollect(viewportData) {
    return setViewPortCollect(prev => ({ ...prev, ...viewportData }))
  }

  function onClickDelivery(viewportData) {
    return setViewPortDelivery(prev => ({ ...prev, ...viewportData }))
  }
  function onOpenPhotos(items) {
    setPhotos(items)
    setLightBox(true)
  }

  useEffect(() => {
    getData()
  }, []) // eslint-disable-line

  if (loading) {
    return <Loading customText="Carregando..." />
  }

  if (result?.length === 0) {
    return (
      <EmptyList
        icon="map marker alternate"
        text="Ainda Não Foram Realizados os Registros de Entrada e Saída"
      />
    )
  }

  return !loading ? (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Map
            items={result?.collectFeed?.map((item, index) => ({
              ...item,
              index,
            }))}
            viewport={viewportCollect}
            onChange={onClickCollect}
          />
          <Card centered fluid>
            <Card.Content>
              {result?.result?.observation ? (
                <div>
                  <Header as="h1">Item Não Coletado em {missionName}</Header>
                  <Header as="h3">Endereço: {place}</Header>
                  <Divider />
                  <Header as="h1">Observação</Header>
                  <Header as="h3">{result?.result?.observation}</Header>
                </div>
              ) : (
                <div>
                  <Header as="h1">Item Coletado em {missionName}</Header>
                  <Header as="h3">Endereço: {place}</Header>
                  <Divider />
                  <List>
                    {result?.result?.collect_fields?.fields?.map(dp => {
                      const answer = result?.result?.collect_data?.fields?.find(
                        i => i.id === dp?.id
                      )
                      return (
                        <ListItem style={{ marginBottom: 10 }}>
                          <ListHeader as="h3">{dp?.name}</ListHeader>
                          <p>
                            {Array.isArray(answer?.value) ? (
                              answer?.value?.map(item => (
                                <Image
                                  src={item?.path}
                                  key={item?.path}
                                  onClick={() => onOpenPhotos(answer.value)}
                                  size="medium"
                                  alt="del_img"
                                />
                              ))
                            ) : (
                              <span style={{ fontSize: 14 }}>
                                {answer?.value}
                              </span>
                            )}
                          </p>
                        </ListItem>
                      )
                    })}
                  </List>
                </div>
              )}
            </Card.Content>
          </Card>
        </Grid.Column>

        {result?.result?.delivery_lat !== null ? (
          <Grid.Column>
            <Map
              items={[
                {
                  index: 1,
                  lat: Number(result?.result?.delivery_lat),
                  lng: Number(result?.result?.delivery_lng),
                  action: 'delivered',
                  created_at: new Date(),
                },
              ]}
              viewport={viewportDelivery}
              onChange={onClickDelivery}
            />
            <Card centered fluid>
              <Card.Content>
                {result?.result?.delivery_data !== null ? (
                  <div>
                    <Header as="h1">
                      Entrega em {result?.result?.delivery_name}
                    </Header>
                    <Header as="h3">
                      Endereço: {result?.result?.delivery_address}
                    </Header>
                    <Divider />
                    <List>
                      {result?.result?.delivery_places_data?.fields?.map(dp => {
                        const answer =
                          result?.result?.delivery_data?.fields?.find(
                            i => i.id === dp?.id
                          )
                        return (
                          <ListItem style={{ marginBottom: 10 }}>
                            <ListHeader as="h3">{dp?.name}</ListHeader>
                            <p>
                              {Array.isArray(answer?.value) ? (
                                answer?.value?.map(item => (
                                  <Image
                                    src={item?.path}
                                    key={item?.path}
                                    size="medium"
                                    onClick={() => onOpenPhotos(answer.value)}
                                    alt="del_img"
                                  />
                                ))
                              ) : (
                                <span style={{ fontSize: 14 }}>
                                  {answer?.value}
                                </span>
                              )}
                            </p>
                          </ListItem>
                        )
                      })}
                    </List>
                  </div>
                ) : null}
              </Card.Content>
            </Card>
          </Grid.Column>
        ) : null}
      </Grid.Row>
      <ModalGateway>
        {lightBox ? (
          <Modal onClose={() => setLightBox(false)}>
            <Carousel
              showThumbnails
              views={photos.map(i => ({ ...i, source: i.path }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Grid>
  ) : null
}

Delivery.propTypes = {
  place: t.string,
  id: t.string,
  missionName: t.string,
}
